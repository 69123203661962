var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{ref:"map",attrs:{"id":"map"}}),_c('UpdateObjectDialog',{attrs:{"active":_vm.dialogs.updateObject,"object":_vm.selectedObject}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]},[_c('div',{ref:"infowindow_content"},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.selectedObject.label)+" ")]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.selectedObject.properties.Stationskennzeichen ? _vm.selectedObject.properties.Stationskennzeichen + " -" : "")+" "+_vm._s(_vm.selectedObject.properties.Schaltstellenkennzeichen ? _vm.selectedObject.properties.Schaltstellenkennzeichen + " -" : "")+" "+_vm._s(_vm.selectedObject.properties.Schlüsselbezeichnung ? _vm.selectedObject.properties.Schlüsselbezeichnung + " -" : "")+" "+_vm._s(_vm.selectedObject.anlagenKuerzel ? _vm.selectedObject.anlagenKuerzel + " -" : "")+" "+_vm._s(_vm.selectedObject.mainCategory == "In Planung" || _vm.selectedObject.mainCategory == "In Umsetzung" ? _vm.selectedObject.mainCategory + " -" : "")+" "+_vm._s(_vm.selectedObject.postal_code ? _vm.selectedObject.postal_code + " -" : "")+" "+_vm._s(_vm.selectedObject.city ? _vm.selectedObject.city + " -" : "")+" "+_vm._s(_vm.getObjectType(_vm.selectedObject))+" ")]),(_vm.selectedObject.mainCategory == 'Ladesäule')?[(_vm.selectedObject.properties?.totalACAmount > 0)?_c('v-card-text',[_vm._v(" Ladepunkt "),_c('span',{class:_vm.selectedObject.properties?.availableACAmount > 0
                  ? 'green--text'
                  : 'red--text'},[_vm._v(" "+_vm._s(_vm.selectedObject.properties?.availableACAmount)+"/"+_vm._s(_vm.selectedObject.properties?.totalACAmount)+" ")]),_c('span',{staticClass:"font-italic"},[_vm._v(" (AC) Available/Gesamt ")])]):_vm._e(),(_vm.selectedObject.properties?.totalDCAmount > 0)?_c('v-card-text',[_vm._v(" Schnellladepunkt "),_c('span',{class:_vm.selectedObject.properties?.availableDCAmount > 0
                  ? 'green--text'
                  : 'red--text'},[_vm._v(" "+_vm._s(_vm.selectedObject.properties?.availableDCAmount)+"/"+_vm._s(_vm.selectedObject.properties?.totalDCAmount)+" ")]),_c('span',{staticClass:"font-italic"},[_vm._v(" (DC) Available/Gesamt ")])]):_vm._e()]:_vm._e(),_c('v-card-text',{staticClass:"pb-0"},[_vm._v(" "+_vm._s(_vm.selectedObject.properties.Zuwegung ? "Zuwegung: " + _vm.selectedObject.properties.Zuwegung : "")+" "+_vm._s(_vm.selectedObject.properties.description ? _vm.selectedObject.properties.description : "")+" ")]),(
            _vm.selectedObject.mainCategory == 'In Planung' ||
            _vm.selectedObject.mainCategory == 'In Umsetzung'
          )?_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","icon":"","small":""},on:{"click":_vm.openUpdateDialog}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)],1):_vm._e(),_c('v-card-actions',{staticClass:"py-0"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"href":_vm.getNavigationURL(_vm.selectedObject),"target":"_blank","small":""}},[_vm._v(" Navigieren ")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.toggleToRoutePlanner(_vm.selectedObject)}}},[_c('v-icon',[_vm._v(" mdi-map-marker-plus ")]),_vm._v(" Routenplaner ")],1)],1)],1)],1)],1)],2)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }